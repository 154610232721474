import "./Spinner.css";

interface Props {
  active: boolean;
}

export const Spinner = (props: Props) => {
  return (
    <>
      <section
        className={`spin center spin1 ${
          props.active ? "visible" : "invisible"
        }`}
      ></section>
      <section
        className={`spin center spin2 ${
          props.active ? "visible" : "invisible"
        }`}
      ></section>
    </>
  );
};
