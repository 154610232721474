import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { fetchColetas, fetchPedido } from "../Helpers/fetch";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";

export const Coletas = () => {
  const { numero_pedido } = useParams();
  const navigate = useNavigate();

  const {
    status: statusPedido,
    data: pedido,
    isLoading: loadingPedido,
  } = useQuery({
    queryKey: ["pedido", numero_pedido],
    queryFn: () => fetchPedido(numero_pedido!),
    enabled: !!numero_pedido,
  });
  const {
    status: statusColeta,
    data: coletas,
    isLoading: loadingColetas,
  } = useQuery({
    queryKey: ["coletas", numero_pedido],
    queryFn: () => fetchColetas(numero_pedido!),
    enabled: !!numero_pedido,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {loadingPedido ? (
              <Spinner active />
            ) : statusPedido === "success" ? (
              <section id="card-resumo">
                <Card
                  class=""
                  title={`Pedido ${pedido.numero_pedido}`}
                  subtitle="Produtos:"
                  content={pedido.produtos.map((produto) => {
                    return (
                      <section key={produto.descricao}>
                        {produto.descricao}: <br />
                        &emsp;Qtd Total: {produto.qtdTotal} <br />
                        &emsp;Qtd Pendente: {produto.qtdPendente} <br />
                        &emsp;Qtd Parcial: {produto.qtdParcial}
                        <br />
                        <br />
                      </section>
                    );
                  })}
                  icons={[
                    { href: "/logistica/estoque_reservado", icon: "21d0" },
                  ]}
                />
              </section>
            ) : (
              <article>Não há informações de pedidos!</article>
            )}
            <section className="focus">
              {loadingColetas ? (
                <Spinner active />
              ) : statusColeta === "success" && coletas.length > 0 ? (
                coletas.map((coleta) => {
                  return (
                    <section key={coleta.codigo} className="ped">
                      <button
                        style={{ background: "none", boxShadow: "none" }}
                        onClick={() =>
                          navigate(
                            `/logistica/estoque_reservado/${numero_pedido}/${coleta.numero_pedido}`
                          )
                        }
                      >
                        <Card
                          class=""
                          title={`Entrega ${new Date(
                            coleta.entrega
                          ).toLocaleDateString("pt-br", {
                            timeZone: "UTC",
                          })}`}
                          subtitle={`Coleta ${coleta.numero_pedido}`}
                          content={coleta.produtos.map((produto) => {
                            return (
                              <section key={produto.descricao}>
                                <strong style={{ fontWeight: "bold" }}>
                                  {produto.descricao}
                                </strong>{" "}
                                - {produto.quantidade}
                                <br />
                                <br />
                              </section>
                            );
                          })}
                        />
                      </button>
                    </section>
                  );
                })
              ) : (
                <article>Não há informações de coletas!</article>
              )}
            </section>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );
};
