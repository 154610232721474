import "./Botao-Inicial.css";

interface Props {
  title: string;
  imgURL: string;
  class: string;
  href: string;
  alt: string;
  active: boolean;
}

export const BotaoInicial = (props: Props) => {
  return (
    <a href={props.href}>
      <button
        className={props.active ? `btn-inicial ${props.class}` : `unreachable`}
        title={props.title}
      >
        <img src={props.imgURL} alt={props.alt} /> <br />
        <h1> {props.title} </h1>
      </button>
    </a>
  );
};
