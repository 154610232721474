import "./Legenda.css";

interface Props {
  items: Record<string, string>;
  active: boolean;
  onToggle(): void;
}

export const Legenda = (props: Props) => {
  const statuses = Object.keys(props.items);
  const titles = Object.values(props.items);

  return (
    <>
      <section className={`legenda ${props.active && "active"}`}>
        {statuses.map((status, idx) => {
          return (
            <article key={status} className={`status-legenda ${status}`}>
              {titles[idx]}
            </article>
          );
        })}
      </section>
      <section className={`det-legenda ${props.active && "active"}`}>
        <button className="btn-legenda" onClick={props.onToggle}>
          Legenda
        </button>
      </section>
    </>
  );
};
