import { useQuery } from "@tanstack/react-query";

import { Splash } from "../../../Components/Splash/Splash";

import { FluxoEstoque } from "../Graficos/FluxoEstoque";

import {
  fetchDeficitProducao,
  fetchEstoqueProgramado,
  fetchPaineisEntregar,
} from "../Helpers/fetch";

import { Spinner } from "../../../Components/Spinner/Spinner";

export const SplashLogistica = () => {
  const dia = new Date().toISOString().split("T")[0];

  const {
    status: entregaStatus,
    data: entregas,
    isLoading: loadingEntregas,
  } = useQuery({
    queryKey: ["entregas", dia],
    queryFn: () => fetchPaineisEntregar(dia),
    enabled: !!dia,
  });
  const {
    status: estoqueStatus,
    data: estoque,
    isLoading: loadingEstoque,
  } = useQuery({
    queryKey: ["estoque", dia],
    queryFn: () => fetchEstoqueProgramado(dia),
    enabled: !!dia,
  });
  const {
    status: deficitStatus,
    data: deficit,
    isLoading: loadingDeficit,
  } = useQuery({
    queryKey: ["deficit", dia],
    queryFn: () => fetchDeficitProducao(dia),
    enabled: !!dia,
  });

  return (
    <Splash
      ativo={[false, false, true]}
      grafico={
        loadingEntregas || loadingEstoque || loadingDeficit ? (
          <Spinner active />
        ) : entregaStatus === "success" &&
          estoqueStatus === "success" &&
          deficitStatus === "success" ? (
          <FluxoEstoque
            dados={{
              dias: entregas
                .map((entrega) => {
                  const dias: string[] = [];
                  dias.push(entrega.programacao);
                  return dias;
                })
                .flat(),
              qtdAEntregar: entregas
                .map((entrega) => {
                  const quantidade: number[] = [];
                  quantidade.push(
                    entrega.paineis.reduce(
                      (accumulator, painel) => accumulator + painel.quantidade,
                      0
                    )
                  );
                  return quantidade;
                })
                .flat(),
              qtdEstoqueProg: estoque
                .map((est) => {
                  const quantidade: number[] = [];
                  quantidade.push(
                    est.paineis.reduce(
                      (accumulator, painel) => accumulator + painel.quantidade,
                      0
                    )
                  );
                  return quantidade;
                })
                .flat(),
              qtdDeficitProd: deficit
                .map((def) => {
                  const quantidade: number[] = [];
                  quantidade.push(
                    def.paineis.reduce(
                      (accumulator, painel) => accumulator + painel.quantidade,
                      0
                    )
                  );
                  return quantidade;
                })
                .flat(),
            }}
          />
        ) : (
          <FluxoEstoque
            dados={{
              dias: [],
              qtdAEntregar: [],
              qtdEstoqueProg: [],
              qtdDeficitProd: [],
            }}
          />
        )
      }
      setor="logistica"
      caminhos={[
        "pedidos",
        "fluxo_de_estoque",
        `gestão_de_estoque/${new Date().toISOString().split("T")[0]}`,
        `estoque_pátio/${new Date().toISOString().split("T")[0]}`,
        "estoque_reservado",
      ]}
    />
  );
};
