import "../Producao.css";

import { useQuery } from "@tanstack/react-query";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";
import { fetchBaterias, fetchDadosProd } from "../Helpers/fetch";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { EficienciaProd } from "../Graficos/EficienciaProd";

export const ProducaoBaterias = () => {
  const {
    status: bateriaStatus,
    data: bateriasData,
    isLoading: bateriasLoading,
  } = useQuery({
    queryKey: ["baterias"],
    queryFn: fetchBaterias,
  });

  const {
    status: prodStatus,
    data: proData,
    isLoading: prodLoading,
  } = useQuery({
    queryKey: ["dadosProd", bateriasData],
    queryFn: () => fetchDadosProd(bateriasData),
    enabled: !!bateriasData,
  });

  return (
    <>
      <Navegacao ativo={[true, false, false]} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {bateriasLoading || prodLoading ? (
              <Spinner active />
            ) : bateriaStatus === "success" && prodStatus === "success" ? (
              <section className="baterias">
                {proData && proData.length > 0 ? (
                  proData.map((producao, idx) => {
                    return (
                      <article key={bateriasData[idx].referencia}>
                        <EficienciaProd
                          title={bateriasData[idx].referencia}
                          dados={producao}
                        />
                      </article>
                    );
                  })
                ) : (
                  <article></article>
                )}
              </section>
            ) : (
              <article>Erro ao receber dados de produção!</article>
            )}
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );
};
