import { AxiosError } from "axios";
import { pcp } from "../Configs/Axios";

export const fetchUnidades = (): Promise<Unidade[]> =>
  pcp
    .get("/login/unidades")
    .then((result) => result.data)
    .catch((error) => {
      if (error instanceof AxiosError)
        if (error.status === 403) localStorage.clear();
    });
