import "./Card.css";

interface Props {
  title: string;
  subtitle: string;
  content: React.ReactElement | React.ReactElement[];
  class?: string;
  icons?: {
    href: string;
    icon: string;
  }[];
}

export const Card = (props: Props) => {
  return (
    <article className={`card ${props.class}`}>
      <h2 className="title">
        <strong>{props.title}</strong>
      </h2>
      <span className="subtitle">{props.subtitle}</span>
      {props.content}
      <section id="icon">
        {props.icons?.map((icon) => {
          return (
            <a href={icon.href} key={icon.icon}>
              <button>
                <span className="icon">
                  {String.fromCharCode(parseInt(icon.icon, 16))}
                </span>
              </button>
            </a>
          );
        })}
      </section>
    </article>
  );
};
