import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { fetchPedidos } from "../Helpers/fetch";
import { capitalize } from "../../../Helpers/string";

export const EstoqueRes = () => {
  const [pedidos, setPedidos] = useState<Pedido[]>([]);

  const {
    status: pedidoStatus,
    data: pedidosData,
    isLoading: loadingPedidos,
  } = useQuery({
    queryKey: ["pedidos"],
    queryFn: fetchPedidos,
  });
  useEffect(() => {
    if (pedidoStatus === "success") setPedidos(pedidosData);
  }, [pedidoStatus, pedidosData]);

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleSearchChange}
              className="edit"
            >
              <input
                type="text"
                name="buscador"
                id="buscador"
                className="search"
                placeholder="Nº ou Cliente"
              />
            </form>
          </section>
          <section id="main-content">
            <section className="focus">
              {loadingPedidos ? (
                <Spinner active />
              ) : pedidos.length > 0 ? (
                pedidos.map((pedido) => {
                  return (
                    <a
                      href={`estoque_reservado/${pedido.numero_pedido}`}
                      key={pedido.numero_pedido}
                    >
                      <Card
                        class=""
                        title={`Pedido ${pedido.numero_pedido}`}
                        subtitle={pedido.nome}
                        content={
                          <article>
                            <span className="subtitle">Endereço:</span>
                            <br />
                            {`${capitalize(pedido.rua)},
                            ${pedido.numero},
                            ${capitalize(pedido.bairro)},
                            ${capitalize(pedido.cidade.slice(0, -5))},
                            ${pedido.sigla}`}
                          </article>
                        }
                      />
                    </a>
                  );
                })
              ) : (
                <article>Nâo há pedidos cadastrados no sistema!</article>
              )}
            </section>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );

  function handleSearchChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const buscador = event.currentTarget.buscador.value;

    if (pedidosData) {
      if (buscador === "") return setPedidos(pedidosData);

      if (parseInt(buscador)) {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.numero_pedido.toString().includes(buscador);
          })
        );
      } else {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.nome.toLowerCase().includes(buscador.toLowerCase());
          })
        );
      }
    }
  }
};
