import "../Pedido.css";

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Legenda } from "../../../Components/Legenda/Legenda";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { toggleActive, toggleList } from "../../../Helpers/toggle";

import { fetchPedidos, fetchProgColetas } from "../Helpers/fetch";
import { capitalize } from "../../../Helpers/string";
import { fetchProdutos } from "../../Producao/Helpers/fetch";

export const Pedidos = () => {
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [dia, setDia] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [navActive, setNav] = useState<boolean[]>([true, false]);
  const [active, setActive] = useState<boolean>(false);
  const [grid, setGrid] = useState<boolean>(true);
  const navegacao = ["Pedidos", "Programação"];

  const {
    status: pedidoStatus,
    data: pedidosData,
    isLoading: loadingPedidos,
  } = useQuery({
    queryKey: ["pedidos"],
    queryFn: fetchPedidos,
  });
  useEffect(() => {
    if (pedidoStatus === "success")
      setPedidos(
        pedidosData.sort(
          (a, b) =>
            new Date(a.entrega!).getTime() - new Date(b.entrega!).getTime()
        )
      );
  }, [pedidoStatus, pedidosData]);

  const {
    status: progStatus,
    data: progData,
    isLoading: progLoading,
  } = useQuery({
    queryKey: ["progColetas", dia],
    queryFn: () => fetchProgColetas(dia),
    enabled: !!dia,
  });

  const { data: proData } = useQuery({
    queryKey: ["produtos"],
    queryFn: fetchProdutos,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <select name="status" id="status">
                <option value="todos">Status</option>
                <option value="fat-pendente">Faturamento Pendente</option>
                <option value="faturado">Faturado</option>
                <option value="pag-pendente">Pagamento Pendente</option>
                <option value="agendado">Agendado</option>
                <option value="confirmado">Confirmado</option>
                <option value="coleta-parcial">Coleta Parcial</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className={`edit ${(!navActive[0] || grid) && "unreachable"}`}
            >
              <select name="frete" id="frete">
                <option value="todos">Frete</option>
                <option value="cif">CIF</option>
                <option value="fob">FOB</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className={`edit ${(!navActive[0] || grid) && "unreachable"}`}
            >
              <input
                list="tipos-painel"
                name="tipoPainel"
                id="tipoPainel"
                placeholder="Tipo de Painel"
              />
              <datalist id="tipos-painel">
                <option value="Nenhum"></option>
                {proData?.map((produto) => {
                  return (
                    <option
                      key={produto.descricao}
                      value={produto.descricao}
                    ></option>
                  );
                })}
              </datalist>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleSearchChange}
              className={`edit ${!navActive[0] && "unreachable"}`}
            >
              <input
                type="text"
                name="buscador"
                id="buscador"
                className="search"
                placeholder="Nº ou Cliente"
              />
            </form>
            <button
              className={`${!navActive[0] && "unreachable"}`}
              onClick={() => toggleActive(setGrid, grid)}
            >
              {grid ? String.fromCharCode(0x2630) : String.fromCharCode(0x25a6)}
            </button>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className={`edit ${!navActive[1] && "unreachable"}`}
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <Legenda
              active={active}
              onToggle={() => toggleActive(setActive, active)}
              items={{
                "fat-pendente": "Faturamento Pendente",
                faturado: "Faturado",
                "pag-pendente": "Pagamento Pendente",
                agendado: "Agendado",
                confirmado: "Confirmado",
                "coleta-parcial": "Coleta Parcial",
              }}
            />
            <section
              className={`focus ${(!navActive[0] || !grid) && "unreachable"}`}
            >
              {loadingPedidos ? (
                <Spinner active />
              ) : pedidos.length > 0 ? (
                pedidos.map((pedido) => {
                  return (
                    <a
                      href={`pedidos/${pedido.numero_pedido}`}
                      key={pedido.numero_pedido}
                    >
                      <Card
                        class={pedido.status}
                        title={`Pedido ${pedido.numero_pedido}`}
                        subtitle={pedido.nome}
                        content={
                          <article>
                            <span className="subtitle">Endereço:</span>
                            <br />
                            {`${capitalize(pedido.rua)},
                            ${pedido.numero},
                            ${capitalize(pedido.bairro)},
                            ${capitalize(pedido.cidade.slice(0, -5))},
                            ${pedido.sigla}`}
                          </article>
                        }
                      />
                    </a>
                  );
                })
              ) : (
                <article>Nâo há pedidos cadastrados no sistema!</article>
              )}
            </section>
            <section
              className={`lista ${(!navActive[0] || grid) && "unreachable"}`}
            >
              {loadingPedidos ? (
                <Spinner active />
              ) : pedidos.length > 0 ? (
                <section id="lista">
                  <section className="item">
                    <article>Cliente</article>
                    <article>Nº Pedido</article>
                    <article>Frete</article>
                    <article>Próxima Entrega:</article>
                  </section>
                  {pedidos.map((pedido) => {
                    return (
                      <a
                        href={`pedidos/${pedido.numero_pedido}`}
                        className={`item ${pedido.status}`}
                      >
                        <article className="nome">{pedido.nome}</article>
                        <article>{pedido.numero_pedido}</article>
                        <article>{pedido.frete.toUpperCase()}</article>
                        <article>
                          {pedido.entrega
                            ? new Date(pedido.entrega).toLocaleDateString(
                                "pt-br",
                                { timeZone: "UTC" }
                              )
                            : ""}
                        </article>
                      </a>
                    );
                  })}
                </section>
              ) : (
                <article>Não há pedidos cadastrados no sistema!</article>
              )}
            </section>
            <section className={`focus prog ${!navActive[1] && "unreachable"}`}>
              {progLoading ? (
                <Spinner active />
              ) : progStatus === "success" ? (
                progData.map((prog) => {
                  return (
                    <section key={prog.programacao} className="programacao">
                      <h2 className="title">{prog.programacao}</h2>
                      {prog.coletas.map((coleta) => {
                        return (
                          <a
                            href={`pedidos/${coleta.num_pedido_mae}`}
                            key={coleta.numero_pedido}
                          >
                            <article className={`coletas ${coleta.status}`}>
                              <h3>{`Coleta ${coleta.numero_pedido}`}</h3>
                            </article>
                          </a>
                        );
                      })}
                    </section>
                  );
                })
              ) : (
                <article></article>
              )}
            </section>
          </section>
          <section className="nav">
            <section className="tabs">
              {navegacao.map((text, idx) => {
                return (
                  <button
                    key={text}
                    onClick={() => toggleList(setNav, navegacao, text)}
                    className={`tab ${navActive[idx] ? "in" : "out"}`}
                    type="button"
                  >
                    {text}
                  </button>
                );
              })}
            </section>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );

  function handleSearchChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const buscador = event.currentTarget.buscador.value;

    if (pedidosData) {
      if (buscador === "") return setPedidos(pedidosData);

      if (parseInt(buscador)) {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.numero_pedido.toString().includes(buscador);
          })
        );
      } else {
        return setPedidos(
          pedidosData.filter((pedido) => {
            return pedido.nome.toLowerCase().includes(buscador.toLowerCase());
          })
        );
      }
    }
  }

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setDia(event.currentTarget.dia.value);
  }

  function handleChange() {
    const status = document.getElementById("status") as HTMLSelectElement;
    const frete = document.getElementById("frete") as HTMLSelectElement;
    const tipo = document.getElementById("tipoPainel") as HTMLInputElement;

    if (pedidosData) {
      let nextPedidos = pedidosData.sort(
        (a, b) =>
          new Date(a.entrega!).getTime() - new Date(b.entrega!).getTime()
      );

      if (status.value !== "todos")
        nextPedidos = nextPedidos.filter(
          (pedido) => pedido.status === status.value
        );

      if (frete.value !== "todos")
        nextPedidos = nextPedidos.filter(
          (pedido) => pedido.frete === frete.value
        );

      if (tipo.value && tipo.value !== "Nenhum")
        nextPedidos = nextPedidos.filter((pedido) =>
          pedido.produtos.some((produto) => produto.descricao === tipo.value)
        );

      setPedidos(nextPedidos);
    }
  }
};
