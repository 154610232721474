import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { pcp } from "../../../Configs/Axios";

import { fetchColetas } from "../Helpers/fetch";

import { Spinner } from "../../../Components/Spinner/Spinner";
import { AxiosError } from "axios";

interface Props {
  pallet: Pallet;
  visible: boolean;
  fechar(): void;
}

export const Reservar = (props: Props) => {
  const [spin, setSpin] = useState<boolean>(false);
  const [pedido, setPedido] = useState<number>(0);
  const [reserva, setReserva] = useState<boolean>(false);

  const { status, data } = useQuery({
    queryKey: ["coletas", pedido],
    queryFn: () => fetchColetas(pedido.toString()),
    enabled: !!pedido,
  });

  const validarColeta = useCallback(() => {
    if (data) {
      const select = document.getElementById("coleta") as HTMLSelectElement;
      const coleta = data.find((col) => col.codigo.toString() === select.value);

      if (coleta) {
        if (
          coleta.produtos.find(
            (produto) => produto.descricao === props.pallet.descricao
          )
        ) {
          setReserva(true);
        } else {
          setReserva(false);
        }
      }
    }
  }, [data, props.pallet]);

  useEffect(() => {
    if (status === "success") validarColeta();
  }, [status, props.pallet, validarColeta]);

  return (
    <section className={`center ${props.visible ? "visible" : "invisible"}`}>
      <section>
        <form
          onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
          onSubmit={reservar}
          className="apontamento"
        >
          <section className="input">
            <label htmlFor="pedido">Nº Pedido:</label>
            <input
              onChange={handlePedidoChange}
              type="number"
              name="pedido"
              id="pedido"
              onWheel={(event) => event.currentTarget.blur()}
            />
          </section>
          <section className="input">
            <label htmlFor="coleta">Coleta:</label>
            <select onChange={validarColeta} name="coleta" id="coleta">
              {data && data.length > 0 ? (
                data.map((coleta) => {
                  return (
                    <option key={coleta.codigo} value={coleta.codigo}>
                      {`Coleta ${coleta.numero_pedido} - ${new Date(
                        coleta.entrega
                      ).toLocaleDateString("pt-br", { timeZone: "UTC" })}`}
                    </option>
                  );
                })
              ) : (
                <option></option>
              )}
            </select>
          </section>
          <button
            type="submit"
            className={`${!reserva && "disabled"}`}
            disabled={!reserva}
          >
            Reservar
          </button>
        </form>
      </section>
      <section id="icon">
        <button className="close" onClick={props.fechar}>
          <span className="icon">&#x2715;</span>
        </button>
      </section>
      <Spinner active={spin} />
    </section>
  );

  function handlePedidoChange(event: SyntheticEvent) {
    event.preventDefault();

    const pedido = event.currentTarget as HTMLSelectElement;
    setPedido(parseInt(pedido.value));
  }

  async function reservar(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSpin(true);
    try {
      await pcp.patch(`/logistica/estoque/${props.pallet.id}`, {
        coleta: event.currentTarget.coleta.value,
      });

      window.location.reload();
      alert("Pallet reservado com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
