import { pcp } from "../../../Configs/Axios";

export const fetchTracos = (): Promise<Traco[]> =>
  pcp.get("/qualidade/traco").then((result) => result.data);

export const fetchAvaliacao = (dia: string): Promise<Pallet[]> =>
  pcp.get(`/qualidade/avaliacao/${dia}`).then((result) => result.data);

export const fetchPaineis = (id: number): Promise<Painel[]> =>
  pcp.get(`/logistica/pallet/${id}`).then((result) => result.data);
