import "./GraficosQualidade.css";

import ApexCharts from "react-apexcharts";

interface Props {
  dados: DadosQualidade;
}

export function IndicesRefugo(props: Props) {
  return (
    <div className="grafico qualidade">
      <div className="grafico-prod-eficiencia">
        <h4>Refugo</h4>

        <ApexCharts
          type="donut"
          width={"150%"}
          height={"150%"}
          series={Object.values(props.dados)}
          options={{
            labels: ["ENCHIMENTO", "QUEBRA", "MONTAGEM", "TRAÇO"],
            colors: ["#A020F0", "#836d0c", "#008000", "#FF0000"],
            legend: {
              position: "right",
              show: false,
            },

            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: false,
                    name: {
                      show: false,
                      fontSize: "10px",
                    },
                    total: {
                      show: false,
                      fontSize: "10px",
                      color: "#ffffff",
                    },
                  },
                },
              },
            },
          }}
        />
      </div>
      <div className="qualidade-legenda">
        <h5>
          <div className="circle-enchimento" />
          ENCHIMENTO
        </h5>
        <h5>
          <div className="circle-quebra" />
          QUEBRA
        </h5>
        <h5>
          <div className="circle-montagem" />
          MONTAGEM
        </h5>
        <h5>
          <div className="circle-traco" />
          TRAÇO
        </h5>
      </div>
    </div>
  );
}
