import { pcp } from "../../../Configs/Axios";

export const fetchPedidos = (): Promise<Pedido[]> =>
  pcp.get("/logistica/pedidos").then((result) => result.data);

export const fetchPedido = (numero_pedido: string): Promise<Pedido> =>
  pcp
    .get(`/logistica/pedidos/${numero_pedido}`)
    .then((result) => result.data[0]);

export const fetchColetas = (numero_pedido: string): Promise<Coleta[]> =>
  pcp
    .get(`/logistica/pedidos/coleta/${numero_pedido}`)
    .then((result) => result.data);

export const fetchColeta = (numero_coleta: string): Promise<Coleta> =>
  pcp
    .get(`/logistica/pedidos/coleta/detalhes/${numero_coleta}`)
    .then((result) => result.data[0]);

export const fetchPalletsColeta = (numero_coleta: string): Promise<Pallet[]> =>
  pcp
    .get(`/logistica/pedidos/coleta/pallets/${numero_coleta}`)
    .then((result) => result.data);

export const fetchProgColetas = (dia: string): Promise<ProgColeta[]> =>
  pcp
    .get(`/logistica/pedidos/coleta/programacao/${dia}`)
    .then((result) => result.data);

export const fetchPaineisEntregar = (dia: string): Promise<PaineisFluxo[]> =>
  pcp
    .get(`/logistica/fluxo/paineis_a_entregar/${dia}`)
    .then((result) => result.data);

export const fetchEstoqueProgramado = (dia: string): Promise<PaineisFluxo[]> =>
  pcp
    .get(`/logistica/fluxo/estoque_programado/${dia}`)
    .then((result) => result.data);

export const fetchDeficitProducao = (dia: string): Promise<PaineisFluxo[]> =>
  pcp
    .get(`/logistica/fluxo/deficit_producao/${dia}`)
    .then((result) => result.data);

export const fetchEstoqueProd = (dia: string): Promise<Pallet[]> =>
  pcp.get(`/producao/estoque/${dia}`).then((result) => result.data);

export const fetchAcabamento = (dia: string): Promise<Pallet[]> =>
  pcp.get(`/logistica/acabamento/${dia}`).then((result) => result.data);

export const fetchEstoqueDisp = (dia: string): Promise<Pallet[]> =>
  pcp.get(`/logistica/estoque/${dia}`).then((result) => result.data);

export const fetchPaineisProd = (dia: string): Promise<Painel[]> =>
  pcp.get(`/logistica/painel/${dia}`).then((result) => result.data);
