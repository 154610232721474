import { RouteObject } from "react-router-dom";

import { RotasLogadas } from "../Auth/Components/Logged";

import { SplashQualidade } from "./Pages/Splash";
import { ControleTraco } from "./Pages/ControleTraco";
import { Apontamento } from "./Pages/Apontamento";
import { OrdensProducao } from "../Producao/Pages/OrdensProd";

export const qualidadeRouter: RouteObject[] = [
  {
    path: "/qualidade",
    element: <RotasLogadas />,
    children: [
      {
        path: "",
        element: <SplashQualidade />,
      },
      {
        path: "ordens",
        children: [
          {
            path: ":dia/:turno/:status",
            element: <OrdensProducao />,
          },
        ],
      },
      {
        path: "apontamento",
        children: [
          {
            path: ":dia/:turno",
            element: <Apontamento />,
          },
        ],
      },
      {
        path: "controle_de_traco",
        element: <ControleTraco />,
      },
    ],
  },
];
