import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { usuario } from "../../../Configs/Usuario";

import { fetchEstoqueDisp } from "../Helpers/fetch";

import { toggleActive } from "../../../Helpers/toggle";
import { Reservar } from "../Components/Reservar";

export const EstoqueDisp = () => {
  const { dia } = useParams();
  const navigate = useNavigate();
  const [pallet, setPallet] = useState<Pallet>();
  const [apont, setApont] = useState<boolean>(false);

  const { status, data, isLoading } = useQuery({
    queryKey: ["estoqueDisp", dia],
    queryFn: () => fetchEstoqueDisp(dia!),
    enabled: !!dia,
  });
  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section className="focus">
              {isLoading ? (
                <Spinner active />
              ) : status === "success" && data.length > 0 ? (
                data.map((pallet) => {
                  return (
                    <article key={pallet.id} className="ordens">
                      <Card
                        class=""
                        title={`T${pallet.turno} ${pallet.referencia}/${
                          pallet.sequencial
                        } ${pallet.descricao.split("MM ").pop()}`}
                        subtitle={new Date(
                          pallet.programacao
                        ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                        content={
                          <article>
                            <span className="subtitle">{pallet.descricao}</span>
                            <br />
                            Quantidade: {pallet.quantidade}
                          </article>
                        }
                      />
                      <button
                        className={`${
                          pallet.status !== "disponivel" && "disabled"
                        } ${usuario.nivel === "visualizador" && "unreachable"}`}
                        onClick={() => {
                          toggleActive(setApont, apont);
                          setPallet(pallet);
                        }}
                        disabled={pallet.status !== "disponivel"}
                      >
                        Reservar
                      </button>
                    </article>
                  );
                })
              ) : (
                <article>Nenhum painel a reservar!</article>
              )}
            </section>
          </section>
        </section>
        <Reservar
          fechar={() => toggleActive(setApont, apont)}
          pallet={pallet!}
          visible={apont}
        />
        <Atalhos />
      </section>
    </>
  );

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    navigate(`/logistica/estoque_patio/${event.currentTarget.dia.value}`);
  }
};
