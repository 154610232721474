import "./Splash.css";

import { Navegacao } from "../Navegacao/Navegacao";

import { Atalhos } from "../Atalhos/Atalhos";

interface Props {
  ativo: boolean[];
  grafico: React.ReactElement;
  setor: string;
  caminhos: string[];
}

export const Splash = (props: Props) => {
  return (
    <>
      <Navegacao ativo={props.ativo} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {props.grafico}
            <article className="botoes-paginas">
              {props.caminhos.map((caminho) => {
                return (
                  <a
                    key={caminho}
                    href={`/${props.setor}/${caminho
                      .replace("ç", "c")
                      .replace("á", "a")
                      .replace("ã", "a")}`}
                  >
                    <button className="nav-paginas">
                      {caminho.toUpperCase().replace(/_/g, " ").split("/")[0]}
                    </button>
                  </a>
                );
              })}
            </article>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );
};
