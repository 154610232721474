import "./Apontamento.css";

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { fetchPaineis } from "../Helpers/fetch";

import { Spinner } from "../../../Components/Spinner/Spinner";

import { pcp } from "../../../Configs/Axios";
import { AxiosError } from "axios";

interface Props {
  id: number;
  visible: boolean;
  fechar(): void;
}

export const ApontamentoPaineis = (props: Props) => {
  const [spin, setSpin] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [visibility, setVisibility] = useState<boolean[]>([]);

  const { status, data, isLoading } = useQuery({
    queryKey: ["paineis", props.id],
    queryFn: () => fetchPaineis(props.id),
  });

  return (
    <section className={`center ${!props.visible && "unreachable"}`}>
      <section>
        {isLoading ? (
          <Spinner active />
        ) : status === "success" && data.length > 0 ? (
          <>
            <form
              onKeyDown={(event) =>
                event.key === "Enter" && event.preventDefault()
              }
              onChange={mudarAvaliacao}
              onSubmit={avaliar}
              className="edit paineisForm"
            >
              <section className="seq-paineis">
                {data.map((painel, idx) => {
                  return (
                    <section key={idx} className="painel">
                      <label
                        htmlFor={painel.referencia}
                        id={painel.id.toString()}
                        className="aprovado"
                      >
                        {painel.referencia}
                      </label>
                      <select
                        className={`${active && "unreachable"}`}
                        name={painel.id.toString()}
                        id={painel.referencia}
                      >
                        <option value="aprovado">Aprovado</option>
                        <option value="refugo">Refugo</option>
                        <option value="falha">Falha</option>
                      </select>
                      <select
                        className={`${!active && "unreachable"} ${
                          visibility[idx] ? "visible" : "invisible"
                        }`}
                        name={`${painel.id.toString()}-motivo`}
                        id={`${painel.referencia}-motivo`}
                      >
                        <option value="enchimento">
                          Enchimento Insuficiente
                        </option>
                        <option value="quebra">Quebra do Painel</option>
                        <option value="montagem">Montagem Inadequada</option>
                        <option value="traco">Traço Inadequado</option>
                        <option value="cura">Cura Insuficiente</option>
                        <option value="desmolde">Falha no Desmolde</option>
                        <option value="teste">Painel de Teste</option>
                        <option value="limpeza">Limpeza Inadequada</option>
                      </select>
                    </section>
                  );
                })}
              </section>
              <button className="btn-apontar" type="submit">
                Avaliar
              </button>
            </form>
            <section id="icon">
              <button className="close btn-apontar" onClick={props.fechar}>
                <span className="icon">&#x2715;</span>
              </button>
            </section>
          </>
        ) : (
          <article>Nenhum painel a avaliar!</article>
        )}
      </section>
      <Spinner active={spin} />
    </section>
  );

  function mudarAvaliacao(event: React.FormEvent<HTMLFormElement>) {
    const nextVisibility = [];

    if (data) {
      for (const painel of data) {
        const label = document.getElementById(painel.id.toString());
        if (!label) continue;

        const select = document.getElementById(
          painel.referencia
        ) as HTMLSelectElement;
        if (!select) continue;

        select.value !== "aprovado"
          ? nextVisibility.push(true)
          : nextVisibility.push(false);

        label.className = select.value;
      }

      setVisibility(nextVisibility);
    }
  }

  async function avaliar(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (data) {
      if (!active) {
        for (const painel of data) {
          const select = document.getElementById(
            painel.referencia
          ) as HTMLSelectElement;
          if (!select) continue;

          if (select.value !== "aprovado") {
            setActive(true);
            return;
          }
        }

        try {
          setSpin(true);

          for (const painel of data) {
            await pcp.patch(`/qualidade/avaliacao/aprovado/${painel.id}`);
          }

          window.location.reload();
          alert("Avaliação dos painéis atualizada com sucesso!");
        } catch (error) {
          setSpin(false);

          if (error instanceof AxiosError)
            if (error.response)
              return alert(JSON.stringify(error.response.data));

          return alert(`Ocorreu um erro ao processar atualização: ${error}`);
        }
      } else {
        setSpin(true);

        try {
          for (const painel of data) {
            const select = document.getElementById(
              painel.referencia
            ) as HTMLSelectElement;

            const motivo = document.getElementById(
              `${painel.referencia}-motivo`
            ) as HTMLSelectElement;

            switch (select.value) {
              case "aprovado":
                await pcp.patch(`/qualidade/avaliacao/aprovado/${painel.id}`);
                break;
              case "refugo":
                await pcp.patch(`/qualidade/avaliacao/refugo/${painel.id}`, {
                  motivo: motivo.value,
                });
                break;
              case "falha":
                await pcp.patch(`/qualidade/avaliacao/falha/${painel.id}`, {
                  motivo: motivo.value,
                });
                break;
              default:
                break;
            }
          }

          window.location.reload();
          alert("Painéis Avaliados com sucesso!");
        } catch (error) {
          setSpin(false);

          if (error instanceof AxiosError)
            if (error.response)
              return alert(JSON.stringify(error.response.data));

          return alert(`Ocorreu um erro ao processar atualização: ${error}`);
        }
      }
    }
  }
};
