import { RouteObject } from "react-router-dom";

import { RotasLogadas } from "../Auth/Components/Logged";

import { SplashLogistica } from "./Pages/Splash";
import { Pedidos } from "./Pages/Pedidos";
import { ResumoPedido } from "./Pages/Resumo-Pedido";
import { Fluxo } from "./Pages/Fluxo";
import { EstoqueDisp } from "./Pages/EstoqueDisp";
import { EstoqueRes } from "./Pages/EstoqueRes";
import { Coletas } from "./Pages/Coletas";
import { Coleta } from "./Pages/Coleta";
import { Editar } from "./Pages/Editar";
import { GestaoDeEstoque } from "./Pages/GestaoDeEstoque";

export const logisticaRouter: RouteObject[] = [
  {
    path: "/logistica",
    element: <RotasLogadas />,
    children: [
      {
        path: "",
        element: <SplashLogistica />,
      },
      {
        path: "pedidos",
        children: [
          {
            index: true,
            element: <Pedidos />,
          },
          {
            path: ":numero_pedido",
            element: <ResumoPedido />,
          },
        ],
      },
      {
        path: "fluxo_de_estoque",
        element: <Fluxo />,
      },
      {
        path: "gestao_de_estoque",
        children: [
          {
            path: ":dia",
            children: [
              {
                index: true,
                element: <GestaoDeEstoque />,
              },
              {
                path: "pallet/:id",
                element: <Editar />,
              },
            ],
          },
        ],
      },
      {
        path: "estoque_patio",
        children: [
          {
            path: ":dia",
            element: <EstoqueDisp />,
          },
        ],
      },
      {
        path: "estoque_reservado",
        children: [
          {
            index: true,
            element: <EstoqueRes />,
          },
          {
            path: ":numero_pedido",
            children: [
              {
                index: true,
                element: <Coletas />,
              },
              {
                path: ":numero_coleta",
                element: <Coleta />,
              },
            ],
          },
        ],
      },
    ],
  },
];
