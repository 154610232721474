import { Splash } from "../../../Components/Splash/Splash";
import { IndicesFalha } from "../Graficos/IndicesFalha";
import { IndicesRefugo } from "../Graficos/IndicesRefugo";

export const SplashQualidade = () => {
  return (
    <Splash
      ativo={[false, true, false]}
      grafico={
        <section className="graficos-qualidade">
          <IndicesRefugo
            dados={{
              perc_enchimento: 0.25,
              perc_montagem: 0.25,
              perc_quebra: 0.25,
              perc_traco: 0.25,
            }}
          />
          <IndicesFalha
            dados={{
              perc_enchimento: 0.25,
              perc_montagem: 0.25,
              perc_quebra: 0.25,
              perc_traco: 0.25,
            }}
          />
        </section>
      }
      setor="qualidade"
      caminhos={[
        `ordens/${new Date().toISOString().split("T")[0]}/1/todos`,
        `apontamento/${new Date().toISOString().split("T")[0]}/1`,
        "controle_de_traço",
      ]}
    />
  );
};
