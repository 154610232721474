import { Navigate, Outlet } from "react-router-dom";

const usuarioLogado = () => {
  const usuario = localStorage.getItem("usuario");
  if (!usuario) return false;

  return true;
};

export const RotasLogadas = () => {
  const logado = usuarioLogado();

  return logado ? <Outlet /> : <Navigate to="/auth" replace />;
};
