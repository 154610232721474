import "../Pedido.css";

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { toggleList } from "../../../Helpers/toggle";

import {
  fetchDeficitProducao,
  fetchEstoqueProgramado,
  fetchPaineisEntregar,
} from "../Helpers/fetch";

import { FluxoEstoque } from "../Graficos/FluxoEstoque";

export const Fluxo = () => {
  const [dia, setDia] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [paineisEntregar, setPaineisEntregar] = useState<PaineisFluxo[]>([]);
  const [estoqueProg, setEstoqueProg] = useState<PaineisFluxo[]>([]);
  const [deficitProd, setDeficitProd] = useState<PaineisFluxo[]>([]);

  const navegacao = [
    "Fluxo de Estoque",
    "Painéis a Entregar",
    "Estoque Programado",
    "Déficit de Produção",
    "Estoque Disponível",
  ];
  const [navActive, setNav] = useState<boolean[]>([
    true,
    false,
    false,
    false,
    false,
  ]);

  const {
    status: entregaStatus,
    data: entregas,
    isLoading: loadingEntregas,
  } = useQuery({
    queryKey: ["entregas", dia],
    queryFn: () => fetchPaineisEntregar(dia),
    enabled: !!dia,
  });
  const {
    status: estoqueStatus,
    data: estoque,
    isLoading: loadingEstoque,
  } = useQuery({
    queryKey: ["estoque", dia],
    queryFn: () => fetchEstoqueProgramado(dia),
    enabled: !!dia,
  });
  const {
    status: deficitStatus,
    data: deficit,
    isLoading: loadingDeficit,
  } = useQuery({
    queryKey: ["deficit", dia],
    queryFn: () => fetchDeficitProducao(dia),
    enabled: !!dia,
  });

  useEffect(() => {
    if (entregaStatus === "success") setPaineisEntregar(entregas);
    if (estoqueStatus === "success") setEstoqueProg(estoque);
    if (deficitStatus === "success") setDeficitProd(deficit);
  }, [entregaStatus, entregas, estoqueStatus, estoque, deficitStatus, deficit]);

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleTipoPainel}
              className="edit"
            >
              <select name="tipoPainel" id="tipoPainel">
                <option value="todos">Todos</option>
                {entregaStatus === "success" &&
                  entregas.length > 0 &&
                  entregas[0].paineis.map((painel) => {
                    return (
                      <option key={painel.descricao} value={painel.descricao}>
                        {painel.descricao}
                      </option>
                    );
                  })}
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section className={`${!navActive[0] && "unreachable"}`}>
              {loadingEntregas || loadingEstoque || loadingDeficit ? (
                <Spinner active />
              ) : entregaStatus === "success" &&
                estoqueStatus === "success" &&
                deficitStatus === "success" ? (
                <FluxoEstoque
                  dados={{
                    dias: paineisEntregar
                      .map((entrega) => {
                        const dias: string[] = [];
                        dias.push(entrega.programacao);
                        return dias;
                      })
                      .flat(),
                    qtdAEntregar: paineisEntregar
                      .map((entrega) => {
                        const quantidade: number[] = [];
                        quantidade.push(
                          entrega.paineis.reduce(
                            (accumulator, painel) =>
                              accumulator + painel.quantidade,
                            0
                          )
                        );
                        return quantidade;
                      })
                      .flat(),
                    qtdEstoqueProg: estoqueProg
                      .map((est) => {
                        const quantidade: number[] = [];
                        quantidade.push(
                          est.paineis.reduce(
                            (accumulator, painel) =>
                              accumulator + painel.quantidade,
                            0
                          )
                        );
                        return quantidade;
                      })
                      .flat(),
                    qtdDeficitProd: deficitProd
                      .map((def) => {
                        const quantidade: number[] = [];
                        quantidade.push(
                          def.paineis.reduce(
                            (accumulator, painel) =>
                              accumulator + painel.quantidade,
                            0
                          )
                        );
                        return quantidade;
                      })
                      .flat(),
                  }}
                />
              ) : (
                <article>Erro ao selecionar dados de Fluxo!</article>
              )}
            </section>
            <section className={`focus ${!navActive[1] && "unreachable"}`}>
              {loadingEntregas ? (
                <Spinner active />
              ) : (
                paineisEntregar.map((entrega) => {
                  return (
                    <section key={entrega.programacao} className="programacao">
                      <h2 className="title">{entrega.programacao}</h2>
                      {entrega.paineis
                        .filter((painel) => painel.quantidade > 0)
                        .map((painel) => {
                          return (
                            <article
                              key={painel.descricao}
                              className="coletas disabled"
                            >
                              <h3>{painel.descricao}</h3>
                              <p>Quantidade: {painel.quantidade}</p>
                            </article>
                          );
                        })}
                    </section>
                  );
                })
              )}
            </section>
            <section className={`focus ${!navActive[2] && "unreachable"}`}>
              {loadingEstoque ? (
                <Spinner active />
              ) : (
                estoqueProg.map((est) => {
                  return (
                    <section key={est.programacao} className="programacao">
                      <h2 className="title">{est.programacao}</h2>
                      {est.paineis
                        .filter((painel) => painel.quantidade > 0)
                        .map((painel) => {
                          return (
                            <article
                              key={painel.descricao}
                              className="coletas disabled"
                            >
                              <h3>{painel.descricao}</h3>
                              <p>Quantidade: {painel.quantidade}</p>
                            </article>
                          );
                        })}
                    </section>
                  );
                })
              )}
            </section>
            <section className={`focus ${!navActive[3] && "unreachable"}`}>
              {loadingDeficit ? (
                <Spinner active />
              ) : (
                deficitProd.map((def) => {
                  return (
                    <section key={def.programacao} className="programacao">
                      <h2 className="title">{def.programacao}</h2>
                      {def.paineis
                        .filter((painel) => painel.quantidade < 0)
                        .map((painel) => {
                          return (
                            <article
                              key={painel.descricao}
                              className="coletas disabled"
                            >
                              <h3>{painel.descricao}</h3>
                              <p>Quantidade: {painel.quantidade}</p>
                            </article>
                          );
                        })}
                    </section>
                  );
                })
              )}
            </section>
            <section className={`focus ${!navActive[4] && "unreachable"}`}>
              {loadingDeficit ? (
                <Spinner active />
              ) : (
                deficitProd.map((def) => {
                  return (
                    <section key={def.programacao} className="programacao">
                      <h2 className="title">{def.programacao}</h2>
                      {def.paineis
                        .filter((painel) => painel.quantidade > 0)
                        .map((painel) => {
                          return (
                            <article
                              key={painel.descricao}
                              className="coletas disabled"
                            >
                              <h3>{painel.descricao}</h3>
                              <p>Quantidade: {painel.quantidade}</p>
                            </article>
                          );
                        })}
                    </section>
                  );
                })
              )}
            </section>
          </section>
          <section className="nav">
            <section className="tabs">
              {navegacao.map((text, idx) => {
                return (
                  <button
                    key={text}
                    onClick={() => toggleList(setNav, navegacao, text)}
                    className={`tab ${navActive[idx] ? "in" : "out"}`}
                    type="button"
                  >
                    {text}
                  </button>
                );
              })}
            </section>
          </section>
        </section>
        <Atalhos />
      </section>
    </>
  );

  function handleTipoPainel(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const tipoPainel = event.currentTarget.tipoPainel.value;

    if (entregas && estoque && deficit) {
      if (tipoPainel === "todos") {
        setPaineisEntregar(entregas);
        setEstoqueProg(estoque);
        setDeficitProd(deficit);
        return;
      }

      const entregasFiltradas = entregas
        .map((entrega) => {
          return {
            programacao: entrega.programacao,
            paineis: entrega.paineis.filter(
              (painel) => painel.descricao === tipoPainel
            ),
          };
        })
        .flat();
      setPaineisEntregar(entregasFiltradas);

      const estoqueFiltrado = estoque
        .map((est) => {
          return {
            programacao: est.programacao,
            paineis: est.paineis.filter(
              (painel) => painel.descricao === tipoPainel
            ),
          };
        })
        .flat();
      setEstoqueProg(estoqueFiltrado);

      const deficitFiltrado = deficit
        .map((def) => {
          return {
            programacao: def.programacao,
            paineis: def.paineis.filter(
              (painel) => painel.descricao === tipoPainel
            ),
          };
        })
        .flat();
      setDeficitProd(deficitFiltrado);

      return;
    }
  }
  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setDia(event.currentTarget.dia.value);
  }
};
