import ApexCharts from "react-apexcharts";

interface Props {
  title: string;
  dados: DadosProducao;
}

export function EficienciaProd(props: Props) {
  const options = {
    chart: {
      id: "basic-bar",
    },

    legend: {
      show: true,
      fontSize: "20px",
      width: 700,
      labels: {
        color: "#ffffff",
        useSeriesColors: true,
      },
      itemMargin: {
        horizontal: 20,
      },
    },
    title: {
      text: props.title,
      style: {
        color: "#ffffff",
        fontSize: "10",
      },
    },

    xaxis: {
      categories: props.dados.producao,

      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#ffffff",
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      title: {
        text: "Tempo (Dia)",
        align: "left",
        style: {
          color: "#FFFFFF",
        },
      },
    },
    stroke: {
      width: [1, 1, 3],
    },
    grid: {
      borderColor: "#1e1e1c5c",
    },
    colors: ["#B6B92F", "#42B92F", "#B92F2F"],
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#ffffff",
        },
        labels: {
          style: {
            colors: "#ffffff",
          },
        },
        title: {
          text: "Prod. (Painéis)",
          style: {
            color: "#FFFFFF",
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "Prod. Max",
      data: props.dados.prod_max,
    },
    {
      name: "Prod. Ideal",
      data: props.dados.prod_ideal,
    },
    {
      name: "Prod. Real",
      data: props.dados.quantidade,
    },
  ];

  return (
    <div className="grafico">
      <ApexCharts
        series={series}
        options={options}
        type="line"
        width={"100%"}
        height={"100%"}
      />
    </div>
  );
}
