import "../Pedido.css";

import { useState } from "react";
import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { fetchPaineis } from "../../Qualidade/Helpers/fetch";

import { Adicionar } from "../Components/Adicionar";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { toggleActive } from "../../../Helpers/toggle";

import { pcp } from "../../../Configs/Axios";

export const Editar = () => {
  const { id } = useParams();
  const { dia } = useParams();
  const [spin, setSpin] = useState<boolean>(false);
  const [apont, setApont] = useState<boolean>(false);

  const { status, data, isLoading } = useQuery({
    queryKey: ["paineis", id],
    queryFn: () => fetchPaineis(parseInt(id!)),
    enabled: !!id,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <a href={`/logistica/gestao_de_estoque/${dia}`}>
              <button type="button">Voltar</button>
            </a>
          </section>
          <section id="main-content">
            {isLoading ? (
              <Spinner active />
            ) : status === "success" && data.length > 0 ? (
              <>
                <form onSubmit={remover}>
                  <section className="seq-paineis">
                    {data.map((painel, idx) => {
                      return (
                        <section key={idx} className="painel">
                          <label
                            className="disabled"
                            htmlFor={painel.referencia}
                            id={painel.id.toString()}
                          >
                            {painel.referencia}
                          </label>
                          <input
                            type="checkbox"
                            name={painel.id.toString()}
                            id={painel.referencia}
                          />
                        </section>
                      );
                    })}
                  </section>
                  <section className="btn-forms">
                    <button
                      type="button"
                      onClick={() => toggleActive(setApont, apont)}
                    >
                      Adicionar
                    </button>
                    <button type="submit">Remover</button>
                  </section>
                </form>
              </>
            ) : (
              <article>Nenhum painel a remover!</article>
            )}
          </section>
        </section>
        <Adicionar
          id={parseInt(id!)}
          fechar={() => toggleActive(setApont, apont)}
          visible={apont}
        />
        <Spinner active={spin} />
        <Atalhos />
      </section>
    </>
  );

  async function remover(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (data) {
      setSpin(true);

      try {
        for (const painel of data) {
          const input = document.getElementById(
            painel.referencia
          ) as HTMLInputElement;

          if (input.checked)
            await pcp.patch(`/logistica/pallet/remover_painel/${painel.id}`);
        }

        window.location.reload();
        alert("Painéis removidos com sucesso!");
      } catch (error) {
        setSpin(false);

        if (error instanceof AxiosError)
          if (error.response) return alert(JSON.stringify(error.response.data));

        return alert(`Ocorreu um erro ao processar atualização: ${error}`);
      }
    }
  }
};
