import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";
import { ApontamentoPaineis } from "../Components/ApontamentoPaineis";

import { fetchAvaliacao } from "../Helpers/fetch";
import { toggleActive } from "../../../Helpers/toggle";

import { usuario } from "../../../Configs/Usuario";

export const Apontamento = () => {
  const navigate = useNavigate();
  const { dia, turno } = useParams();
  const [id, setId] = useState<number>(0);
  const [apont, setApont] = useState<boolean>(false);
  const [pallets, setPallets] = useState<Pallet[]>([]);

  const { status, data, isLoading } = useQuery({
    queryKey: ["avaliacao", dia],
    queryFn: () => fetchAvaliacao(dia!),
    enabled: !!dia,
  });
  useEffect(() => {
    if (status === "success" && data.length > 0)
      setPallets(data.filter((pallet) => pallet.turno === parseInt(turno!)));
  }, [status, data, turno]);

  return (
    <>
      <Navegacao ativo={[false, true, false]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className="edit"
            >
              <select defaultValue={turno} name="turno" id="turno">
                <option value="1">1º Turno</option>
                <option value="2">2º Turno</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section className="focus">
              {isLoading ? (
                <Spinner active />
              ) : status === "success" && pallets.length > 0 ? (
                pallets.map((pallet) => {
                  return (
                    <article key={pallet.id} className="ordens">
                      <Card
                        class=""
                        title={`T${pallet.turno} ${pallet.referencia}/${
                          pallet.sequencial
                        } ${pallet.descricao.split("MM ").pop()}`}
                        subtitle={new Date(
                          pallet.programacao
                        ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                        content={
                          <article>
                            <span className="subtitle">{pallet.descricao}</span>
                            <br />
                            Quantidade: {pallet.quantidade}
                          </article>
                        }
                      />
                      <button
                        className={`${
                          pallet.status !== "produzido" && "disabled"
                        } ${usuario.nivel === "visualizador" && "unreachable"}`}
                        onClick={() => {
                          setId(pallet.id);
                          setApont(true);
                        }}
                        disabled={pallet.status !== "produzido"}
                      >
                        Avaliar
                      </button>
                    </article>
                  );
                })
              ) : (
                <article>Nenhum painel a avaliar!</article>
              )}
            </section>
          </section>
        </section>
        <ApontamentoPaineis
          id={id}
          visible={apont}
          fechar={() => toggleActive(setApont, apont)}
        />
        <Atalhos />
      </section>
    </>
  );

  function handleChange() {
    const dia = document.getElementById("dia") as HTMLInputElement;
    const turno = document.getElementById("turno") as HTMLSelectElement;

    navigate(`/qualidade/apontamento/${dia.value}/${turno.value}`);
  }
};
