import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { fetchReparo } from "../Helpers/fetch";

import { Card } from "../../../Components/Card/Card";
import { DestinoReparo } from "../Components/DestinoReparo";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { usuario } from "../../../Configs/Usuario";

import { toggleActive } from "../../../Helpers/toggle";

export const Reparo = () => {
  const navigate = useNavigate();
  const { dia } = useParams();

  const [id, setId] = useState<number>(0);
  const [apont, setApont] = useState<boolean>(false);

  const { status, data, isLoading } = useQuery({
    queryKey: ["reparo", dia],
    queryFn: () => fetchReparo(dia!),
    enabled: !!dia,
  });

  return (
    <>
      <Navegacao ativo={[true, false, false]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section className="focus">
              {isLoading ? (
                <Spinner active />
              ) : status === "success" && data.length > 0 ? (
                data.map((painel) => {
                  return (
                    <article key={painel.id} className="ordens">
                      <Card
                        class=""
                        title={`T${painel.turno} ${painel.referencia}/${
                          painel.sequencial
                        } P${painel.posicao} ${painel.descricao
                          .split("MM ")
                          .pop()}`}
                        subtitle={new Date(painel.producao).toLocaleDateString(
                          "pt-br",
                          { timeZone: "UTC" }
                        )}
                        content={
                          <article>
                            <span className="subtitle">{painel.descricao}</span>
                          </article>
                        }
                      />
                      <button
                        className={`${
                          painel.status !== "reparo" && "disabled"
                        } ${usuario.nivel === "visualizador" && "unreachable"}`}
                        onClick={() => {
                          toggleActive(setApont, apont);
                          setId(painel.id);
                        }}
                        disabled={painel.status !== "reparo"}
                      >
                        Avançar
                      </button>
                    </article>
                  );
                })
              ) : (
                <article>Nenhum painel a reparar!</article>
              )}
            </section>
          </section>
        </section>
        <DestinoReparo
          fechar={() => toggleActive(setApont, apont)}
          id={id}
          visible={apont}
        />
        <Atalhos />
      </section>
    </>
  );

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    navigate(`/producao/reparo/${event.currentTarget.dia.value}`);
  }
};
