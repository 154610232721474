import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";

import { authRouter } from "./Routes/Auth/Routes";
import { mainRouter } from "./Routes/Inicial/Routes";
import { logisticaRouter } from "./Routes/Logistica/Routes";
import { producaoRouter } from "./Routes/Producao/Routes";
import { qualidadeRouter } from "./Routes/Qualidade/Routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const routes = [
  {
    path: "/",
    element: <App />,
    children: [
      ...authRouter,
      ...mainRouter,
      ...logisticaRouter,
      ...producaoRouter,
      ...qualidadeRouter,
    ],
  },
];
const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
