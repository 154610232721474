import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useEffect, useState } from "react";

import { pcp } from "../../../Configs/Axios";

import { fetchOrdens } from "../Helpers/fetch";

import { Spinner } from "../../../Components/Spinner/Spinner";
import { AxiosError } from "axios";

interface Props {
  dia: string;
  id: number;
  visible: boolean;
  fechar(): void;
}

export const Apontamento = (props: Props) => {
  const [spin, setSpin] = useState<boolean>(false);
  const [ordem, setOrdem] = useState<Ordem>();
  const [secoes, setSecoes] = useState<any[]>([]);
  const [prodCompleta, setProdCompleta] = useState<boolean[]>([]);

  const { status, data } = useQuery({
    queryKey: ["ordens", props.dia],
    queryFn: () => fetchOrdens(props.dia),
    enabled: !!props.dia,
  });

  useEffect(() => {
    if (status === "success" && data) {
      const ord = data.find((ordem) => ordem.id === props.id);

      if (ord) {
        setOrdem(ord);

        const sect = [];
        for (let idx = 0; idx < ord.quantidade; idx++) {
          sect.push(
            <input
              key={idx.toString()}
              onChange={handleCheck}
              className="secoes-bateria"
              type="checkbox"
              name={idx.toString()}
              id={idx.toString()}
            />
          );
        }
        setSecoes(sect);
      }
    }

    function handleCheck() {
      const producao = [];
      for (let idx = 0; idx < secoes.length; idx++) {
        const check = document.getElementById(
          idx.toString()
        ) as HTMLInputElement;
        producao.push(check.checked);
      }

      setProdCompleta(producao);
    }
  }, [status, data, props.id, secoes.length]);

  return (
    <section className={`center ${props.visible ? "visible" : "invisible"}`}>
      <section>
        <form
          onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
          onSubmit={apontarEnchimento}
          className="apontamento"
        >
          <section className="timing">
            <label htmlFor="inicio_prod">Início Desm.:</label>
            <input type="time" name="inicio_prod" id="inicio_prod" />
            <label htmlFor="inicio">Início:</label>
            <input type="time" name="inicio" id="inicio" required />
            <label htmlFor="final">Final:</label>
            <input type="time" name="final" id="final" required />
          </section>
          <fieldset className="secao-bateria">
            <legend>
              Seções Preenchidas - {ordem?.bateria}/{ordem?.sequencial}{" "}
              {ordem?.turno}T:
            </legend>
            {secoes}
          </fieldset>
          <section
            className={`motivo input ${
              prodCompleta.filter((producao) => producao).length <
                secoes.length && "active"
            }`}
          >
            <label htmlFor="motivo" className="subtitle">
              Motivo:
            </label>
            <select name="motivo" id="motivo">
              <option value="colaborador">Falta Colaborador</option>
              <option value="insumo">Falta Insumo</option>
              <option value="agua-energia">Falta Água / Energia</option>
              <option value="cura">Falta de Cura</option>
              <option value="manutencao">Manutenção Bateria</option>
              <option value="limpeza">Limpeza</option>
              <option value="teste">Realização de Teste</option>
              <option value="carrinho">Quebra do Carrinho</option>
              <option value="troller">Quebra do Troller</option>
              <option value="central">Quebra da Central</option>
              <option value="embuchamento">Embuchamento</option>
            </select>
          </section>
          <label htmlFor="observacoes" className="subtitle">
            Observações:
          </label>
          <input type="text" name="observacoes" id="observacoes" />
          <button type="submit">Apontar</button>
        </form>
      </section>
      <section id="icon">
        <button className="close" onClick={props.fechar}>
          <span className="icon">&#x2715;</span>
        </button>
        <button className="close" onClick={marcarTodas}>
          <span className="icon">&#x2713;</span>
        </button>
      </section>
      <Spinner active={spin} />
    </section>
  );

  function marcarTodas(event: SyntheticEvent) {
    event.preventDefault();

    let ativo = false;

    for (let idx = 0; idx < secoes.length; idx++) {
      const check = document.getElementById(idx.toString()) as HTMLInputElement;
      if (check.checked) {
        ativo = true;
        break;
      }
    }

    if (ativo) {
      for (let idx = 0; idx < secoes.length; idx++) {
        const check = document.getElementById(
          idx.toString()
        ) as HTMLInputElement;
        check.checked = false;
      }
    } else {
      for (let idx = 0; idx < secoes.length; idx++) {
        const check = document.getElementById(
          idx.toString()
        ) as HTMLInputElement;
        check.checked = true;
      }
    }

    const producao = [];
    for (let idx = 0; idx < secoes.length; idx++) {
      const check = document.getElementById(idx.toString()) as HTMLInputElement;
      producao.push(check.checked);
    }

    setProdCompleta(producao);
  }

  async function apontarEnchimento(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    const dadosApontamento = {
      inicio_prod: `${props.dia}T${event.currentTarget.inicio_prod.value}`,
      inicio: `${props.dia}T${event.currentTarget.inicio.value}`,
      final: `${props.dia}T${event.currentTarget.final.value}`,
      qtdProduzida:
        prodCompleta.length > 0
          ? prodCompleta.filter((producao) => producao).length
          : 0,
      qtdNaoRealizada:
        prodCompleta.length > 0
          ? prodCompleta.filter((producao) => !producao).length
          : secoes.length,
      observacoes: event.currentTarget.observacoes.value,
      motivo: event.currentTarget.motivo.value,
      posicoes: prodCompleta.length > 0 ? prodCompleta : null,
    };

    if (dadosApontamento.inicio > dadosApontamento.final)
      return alert("Horário de Início não pode ser maior que Horário Final!");

    try {
      await pcp.post(`/producao/apontamento/${props.id}`, dadosApontamento);

      window.location.reload();
      alert("Produção apontada com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
