export function toggleActive(fn: Function, active: boolean) {
  fn(!active);
}

export function toggleList<T>(fn: Function, array: Array<T>, element: T) {
  const position = array.indexOf(element);
  const nextActives: Array<boolean> = new Array(array.length);

  nextActives[position] = true;

  fn(nextActives);
}
