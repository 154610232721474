import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { fetchBaterias, fetchConfigs, fetchProdutos } from "../Helpers/fetch";
import { toggleList } from "../../../Helpers/toggle";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { pcp } from "../../../Configs/Axios";
import { AxiosError } from "axios";

interface Props {
  dia: string;
  active: boolean;
  fechar(): void;
}

export const Adicionar = (props: Props) => {
  const [spin, setSpin] = useState<boolean>(false);
  const [tabs, setTabs] = useState<string[]>([]);
  const [tabsActive, setTabsActive] = useState<boolean[]>([]);
  const [baterias, setBaterias] = useState<{ bateria: Bateria; seq: number }[]>(
    []
  );

  const { status: configStatus, data: configData } = useQuery({
    queryKey: ["configuracoes"],
    queryFn: fetchConfigs,
  });

  const { status: bateriaStatus, data: bateriasData } = useQuery({
    queryKey: ["baterias"],
    queryFn: fetchBaterias,
  });

  const { data: proData } = useQuery({
    queryKey: ["produtos"],
    queryFn: fetchProdutos,
  });

  useEffect(() => {
    if (configStatus === "success") {
      const tabs: string[] = [];
      const tabsActive: boolean[] = [true];

      for (let idx = 0; idx < configData.qtdTurnos; idx++) {
        tabs.push(`Turno ${idx + 1}`);
        if (idx !== 0) tabsActive.push(false);
      }
      setTabs(tabs);
      setTabsActive(tabsActive);

      if (bateriaStatus === "success") {
        const baterias: {
          bateria: Bateria;
          seq: number;
        }[] = [];

        for (let idx = 0; idx < configData.batTurnos; idx++) {
          let seq = 1;
          if (idx >= bateriasData.length) seq = 2;

          baterias.push({
            bateria: bateriasData[idx % bateriasData.length],
            seq: seq,
          });
        }
        setBaterias(baterias);
      }
    }
  }, [configStatus, configData, bateriaStatus, bateriasData]);

  return (
    <section className={`center ${!props.active && "unreachable"}`} id="config">
      <section className="nav">
        <section className="tabs">
          {tabs.map((text, idx) => {
            return (
              <button
                key={text}
                onClick={() => toggleList(setTabsActive, tabs, text)}
                className={`tab ${tabsActive[idx] ? "in" : "out"}`}
                type="button"
              >
                {text}
              </button>
            );
          })}
        </section>
        <button type="button" onClick={props.fechar}>
          &#x2715;
        </button>
      </section>
      <section className="cont">
        <form
          onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
          onSubmit={criarOrdens}
          id="form-config"
          className="grid"
        >
          {tabs.map((text, idx) => {
            return (
              <section
                key={`${text}${idx}`}
                className={`center-form ${!tabsActive[idx] && "unreachable"}`}
              >
                {bateriaStatus === "success" &&
                  baterias.map((bateria, idxBateria) => {
                    return (
                      <section
                        key={`T${idx + 1}${bateria.bateria.referencia}/${
                          bateria.seq
                        }`}
                      >
                        <fieldset className="input">
                          <legend>{`${bateria.bateria.referencia}/${bateria.seq}`}</legend>
                          <hr />
                          <section style={{ display: "flex", width: "50dvw", gap: "1rem" }}>
                            <input
                              style={{ flex: 1 }}
                              list="tipos-painel"
                              name={`t${idx + 1}_b${
                                (idxBateria % bateriasData.length) + 1
                              }_s${bateria.seq}`}
                              id={`t${idx + 1}_b${
                                (idxBateria % bateriasData.length) + 1
                              }_s${bateria.seq}`}
                              placeholder="Tipo de Painel"
                            />
                            <datalist id="tipos-painel">
                              <option value="Nenhum"></option>
                              {proData?.map((produto) => {
                                return (
                                  <option
                                    key={produto.descricao}
                                    value={produto.descricao}
                                  ></option>
                                );
                              })}
                            </datalist>
                            <input
                              type="number"
                              min={0}
                              name={`t${idx + 1}_b${
                                (idxBateria % bateriasData.length) + 1
                              }_s${bateria.seq}_qtd`}
                              id={`t${idx + 1}_b${
                                (idxBateria % bateriasData.length) + 1
                              }_s${bateria.seq}_qtd`}
                              placeholder="Quantidade"
                              onWheel={(event) => event.currentTarget.blur()}
                            />
                          </section>
                        </fieldset>
                        <br />
                      </section>
                    );
                  })}
              </section>
            );
          })}
          <button className="btn-form" type="submit">
            Adicionar Ordens
          </button>
        </form>
      </section>
      <Spinner active={spin} />
    </section>
  );

  async function criarOrdens(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSpin(true);

    const dadosOrdens: Record<string, Record<string, unknown>> = {};

    const formData = new FormData(event.currentTarget);
    for (let [key, value] of formData.entries()) {
      const keys = key.split("_");

      if (keys.length === 3) {
        if (!dadosOrdens[key]) {
          dadosOrdens[key] = {
            turno: keys[0].split("t").pop(),
            bateria: keys[1].split("b").pop(),
            sequencial: keys[2].split("s").pop(),
            produto: value,
          };
        } else {
          dadosOrdens[key].turno = keys[0].split("t").pop();
          dadosOrdens[key].bateria = keys[1].split("b").pop();
          dadosOrdens[key].sequencial = keys[2].split("s").pop();
          dadosOrdens[key].produto = value;
        }
      } else {
        if (!dadosOrdens[key.slice(0, -4)]) {
          dadosOrdens[key.slice(0, -4)] = {
            quantidade: value,
          };
        } else {
          dadosOrdens[key.slice(0, -4)].quantidade = value;
        }
      }
    }

    try {
      await pcp.post(
        `/producao/ordens/${props.dia}`,
        Object.values(dadosOrdens)
      );

      window.location.reload();
      alert("Ordens de produção adicionadas com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response)
          alert(
            `Ocorreu um erro ao processar atualização: ${error.response.data}`
          );

      alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
