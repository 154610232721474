import { pcp } from "../../../Configs/Axios";

export const fetchBaterias = (): Promise<Bateria[]> =>
  pcp.get("/producao/baterias").then((result) => result.data);

export const fetchProdBaterias = (): Promise<DadosProducao> =>
  pcp.get("/producao/baterias/producao").then((result) => result.data);

export const fetchDadosProd = (baterias: Bateria[] | undefined) => {
  if (baterias) {
    return Promise.all(
      baterias.map((bateria) =>
        pcp
          .get(`/producao/baterias/producao/${bateria.id}`)
          .then((result) => result.data)
      )
    );
  }
};

export const fetchOrdens = (dia: string): Promise<Ordem[]> =>
  pcp.get(`/producao/ordens/${dia}`).then((result) => result.data);

export const fetchTestes = (ordens: Ordem[] | undefined) => {
  if (ordens) {
    return Promise.all(
      ordens.map((ordem) =>
        pcp.get(`/qualidade/testes/${ordem.id}`).then((result) => result.data)
      )
    );
  }
};

export const fetchProdutos = (): Promise<Produto[]> =>
  pcp.get(`/producao/produto`).then((result) => result.data);

export const fetchConsumo = (dia: string): Promise<Consumo> =>
  pcp.get(`/producao/ordens/consumo/${dia}`).then((result) => result.data);

export const fetchReparo = (dia: string): Promise<PainelReparo[]> =>
  pcp.get(`/producao/reparo/${dia}`).then((result) => result.data);

export const fetchConfigs = (): Promise<Configs> =>
  pcp.get(`/producao/configuracoes`).then((result) => result.data);

export const fetchResumo = (dia: string): Promise<Produto[]> =>
  pcp.get(`/producao/ordens/resumo/${dia}`).then((result) => result.data);
