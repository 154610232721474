import axios from "axios";

export const pcp = (() => {
  switch (process.env.NODE_ENV) {
    case "development":
      return axios.create({
        withCredentials: true,
        baseURL: "http://localhost:8000",
      });

    default:
      return axios.create({
        withCredentials: true,
        baseURL: "https://servidor-pcp.lightwallbrasil.com",
      });
  }
})();
