import "../Qualidade.css";

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { fetchTracos } from "../Helpers/fetch";

import { usuario } from "../../../Configs/Usuario";
import { pcp } from "../../../Configs/Axios";
import { AxiosError } from "axios";

export const ControleTraco = () => {
  const [spin, setSpin] = useState<boolean>(false);

  const { status, data, isLoading } = useQuery({
    queryKey: ["tracos"],
    queryFn: fetchTracos,
  });

  return (
    <>
      <Navegacao ativo={[false, true, false]} />
      <section id="main">
        <section id="content">
          <section id="main-content">
            {isLoading ? (
              <Spinner active />
            ) : status === "success" ? (
              <form
                onKeyDown={(event) =>
                  event.key === "Enter" && event.preventDefault()
                }
                onSubmit={atualizarTraco}
                className="tracos"
              >
                <section className="inputs">
                  {data.map((traco) => {
                    return (
                      <section key={traco.tipo}>
                        <h2 style={{ fontWeight: "bold" }}>
                          {traco.tipo.toUpperCase()}
                        </h2>
                        <section>
                          <label htmlFor={`cimento-${traco.tipo}`}>
                            Cimento(kg):
                          </label>
                          <input
                            type="number"
                            min={0}
                            step={0.01}
                            defaultValue={traco.cimento}
                            name={`cimento-${traco.tipo}`}
                            id={`cimento-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <label htmlFor={`eps-${traco.tipo}`}>EPS (kg):</label>
                          <input
                            defaultValue={traco.eps}
                            min="0"
                            step=".01"
                            type="number"
                            name={`eps-${traco.tipo}`}
                            id={`eps-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <label htmlFor={`agua-${traco.tipo}`}>
                            Água (l):
                          </label>
                          <input
                            defaultValue={traco.agua}
                            min="0"
                            type="number"
                            name={`agua-${traco.tipo}`}
                            id={`agua-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <label htmlFor={`superplast-${traco.tipo}`}>
                            Super Plast (ml).:
                          </label>
                          <input
                            defaultValue={traco.super_plast}
                            min="0"
                            step="0.001"
                            type="number"
                            name={`superplast-${traco.tipo}`}
                            id={`superplast-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <label htmlFor={`incorpar-${traco.tipo}`}>
                            Incorp. Ar (ml):
                          </label>
                          <input
                            defaultValue={traco.incorp_ar}
                            min="0"
                            step="0.001"
                            type="number"
                            name={`incorpar-${traco.tipo}`}
                            id={`incorpar-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <label htmlFor={`tempobatimento-${traco.tipo}`}>
                            Tempo Batimento (s):
                          </label>
                          <input
                            defaultValue={traco.tempo_batimento}
                            min="0"
                            type="number"
                            name={`tempobatimento-${traco.tipo}`}
                            id={`tempobatimento-${traco.tipo}`}
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </section>
                      </section>
                    );
                  })}
                </section>
                <button
                  type="submit"
                  className={`${
                    usuario.qualidade &&
                    usuario.nivel !== "administrador" &&
                    "unreachable"
                  }`}
                >
                  Atualizar Traço
                </button>
              </form>
            ) : (
              <article>Erro ao acessar dados de traço!</article>
            )}
          </section>
        </section>
        <Atalhos />
        <Spinner active={spin} />
      </section>
    </>
  );

  async function atualizarTraco(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSpin(true);
    const target = event.currentTarget;

    try {
      if (data)
        for (const traco of data) {
          await pcp.post(`/qualidade/traco/${traco.tipo}`, {
            cimento: target[`cimento-${traco.tipo}`].value,
            eps: target[`eps-${traco.tipo}`].value,
            agua: target[`agua-${traco.tipo}`].value,
            super_plast: target[`superplast-${traco.tipo}`].value,
            incorp_ar: target[`incorpar-${traco.tipo}`].value,
            tempo_batimento: target[`tempobatimento-${traco.tipo}`].value,
          });
        }

      window.location.reload();
      alert("Traços atualizados com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
