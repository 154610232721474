import "./Navegacao.css";

import { useState } from "react";

import { toggleActive } from "../../Helpers/toggle";
import { usuario } from "../../Configs/Usuario";

interface Props {
  ativo: boolean[];
}

export const Navegacao = (props: Props) => {
  const [active, setActive] = useState<boolean>(false);

  return (
    <nav id="navegacao">
      <section id="menu" className={`${active && "active"}`}>
        <a href="/producao" className={`${!usuario.producao && "unreachable"}`}>
          <button
            className={`botao-navegacao producao ${
              !props.ativo[0] && "hidden"
            }`}
          >
            Produção
          </button>
        </a>
        <a
          href="/qualidade"
          className={`${!usuario.qualidade && "unreachable"}`}
        >
          <button
            className={`botao-navegacao qualidade ${
              !props.ativo[1] && "hidden"
            }`}
          >
            Qualidade
          </button>
        </a>
        <a
          href="/logistica"
          className={`${!usuario.logistica && "unreachable"}`}
        >
          <button
            className={`botao-navegacao logistica ${
              !props.ativo[2] && "hidden"
            }`}
          >
            Logística
          </button>
        </a>
      </section>
      <section
        id="hamburger"
        className={`${active && "active"}`}
        onClick={() => toggleActive(setActive, active)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </section>
    </nav>
  );
};
