import "../Pedido.css";

import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  fetchAcabamento,
  fetchEstoqueDisp,
  fetchEstoqueProd,
} from "../Helpers/fetch";

import { Card } from "../../../Components/Card/Card";
import { Spinner } from "../../../Components/Spinner/Spinner";
import { Atalhos } from "../../../Components/Atalhos/Atalhos";
import { Navegacao } from "../../../Components/Navegacao/Navegacao";

import { pcp } from "../../../Configs/Axios";
import { usuario } from "../../../Configs/Usuario";

export const GestaoDeEstoque = () => {
  const navigate = useNavigate();
  const { dia } = useParams();
  const [spin, setSpin] = useState<boolean>(false);

  const {
    status: estProdStatus,
    data: estProduzido,
    isLoading: estProdLoading,
  } = useQuery({
    queryKey: ["estoqueProd", dia],
    queryFn: () => fetchEstoqueProd(dia!),
    enabled: !!dia,
  });

  const {
    status: acabamentoStatus,
    data: acabamento,
    isLoading: acabamentoLoading,
  } = useQuery({
    queryKey: ["acabamento", dia],
    queryFn: () => fetchAcabamento(dia!),
    enabled: !!dia,
  });

  const {
    status: estDispStatus,
    data: estDisponivel,
    isLoading: estDispLoading,
  } = useQuery({
    queryKey: ["estoqueDisp", dia],
    queryFn: () => fetchEstoqueDisp(dia!),
    enabled: !!dia,
  });

  return (
    <>
      <Navegacao ativo={[false, false, true]} />
      <section id="main">
        <section id="content">
          <section id="aux">
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleFunilChange}
              className="edit funil"
            >
              <select name="funil" id="funil">
                <option value="produzido">Estoque Produzido</option>
                <option value="acabamento">Acabamento</option>
                <option value="disponivel">Estoque Disponivel</option>
              </select>
            </form>
            <form
              onSubmit={(event) => event.preventDefault()}
              onChange={handleDiaChange}
              className="edit"
            >
              <input type="date" name="dia" id="dia" defaultValue={dia} />
            </form>
          </section>
          <section id="main-content">
            <section id="estoque" className="focus">
              <section className="estoque" id="produzido">
                <span className="subtitle">Estoque Produzido:</span>
                {estProdLoading ? (
                  <Spinner active />
                ) : estProdStatus === "success" && estProduzido.length > 0 ? (
                  estProduzido.map((pallet) => {
                    return (
                      <article key={pallet.id} className="ordens">
                        <Card
                          class=""
                          title={`T${pallet.turno} ${pallet.referencia}/${
                            pallet.sequencial
                          } ${pallet.descricao.split("MM ").pop()}`}
                          subtitle={new Date(
                            pallet.programacao
                          ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                          content={
                            <article>
                              <span className="subtitle">
                                {pallet.descricao}
                              </span>
                              <br />
                              Quantidade: {pallet.quantidade}
                            </article>
                          }
                        />
                        <button
                          id={pallet.id.toString()}
                          className={`${
                            pallet.status !== "produzido" && "disabled"
                          } ${
                            usuario.nivel === "visualizador" && "unreachable"
                          }`}
                          onClick={avancarProducao}
                          disabled={pallet.status !== "produzido"}
                        >
                          Avançar
                        </button>
                      </article>
                    );
                  })
                ) : (
                  <article>Nenhum painel a realizar acabamento!</article>
                )}
                <hr style={{ border: "1px solid white" }} />
                <button
                  onClick={avancarTodaProducao}
                  className={`${
                    estProduzido && estProduzido.length <= 0 && "disabled"
                  }`}
                  disabled={estProduzido && estProduzido.length <= 0}
                >
                  Avançar Todos
                </button>
              </section>
              <section className="estoque" id="acabamento">
                <span className="subtitle">Acabamento:</span>
                {acabamentoLoading ? (
                  <Spinner active />
                ) : acabamentoStatus === "success" && acabamento.length > 0 ? (
                  acabamento.map((pallet) => {
                    return (
                      <article key={pallet.id} className="ordens">
                        <button
                          style={{
                            background: "none",
                            boxShadow: "none",
                            textAlign: "left",
                            padding: 0,
                          }}
                          onClick={() => navigate(`pallet/${pallet.id}`)}
                        >
                          <Card
                            class=""
                            title={`T${pallet.turno} ${pallet.referencia}/${
                              pallet.sequencial
                            } ${pallet.descricao.split("MM ").pop()}`}
                            subtitle={new Date(
                              pallet.programacao
                            ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                            content={
                              <article>
                                <span className="subtitle">
                                  {pallet.descricao}
                                </span>
                                <br />
                                Quantidade: {pallet.quantidade}
                              </article>
                            }
                          />
                        </button>
                        <button
                          id={pallet.id.toString()}
                          className={`${
                            pallet.status !== "acabamento" && "disabled"
                          } ${
                            usuario.nivel === "visualizador" && "unreachable"
                          }`}
                          onClick={avancarAcabamento}
                          disabled={pallet.status !== "acabamento"}
                        >
                          Avançar
                        </button>
                      </article>
                    );
                  })
                ) : (
                  <article>Nenhum painel a estocar!</article>
                )}
                <hr style={{ border: "1px solid white" }} />
                <button
                  onClick={avancarTodoAcabamento}
                  className={`${
                    acabamento && acabamento.length <= 0 && "disabled"
                  }`}
                  disabled={acabamento && acabamento.length <= 0}
                >
                  Avançar Todos
                </button>
              </section>
              <section className="estoque" id="disponivel">
                <span className="subtitle">Estoque Disponível:</span>
                {estDispLoading ? (
                  <Spinner active />
                ) : estDispStatus === "success" && estDisponivel.length > 0 ? (
                  estDisponivel.map((pallet) => {
                    return (
                      <article key={pallet.id} className="ordens">
                        <Card
                          class=""
                          title={`T${pallet.turno} ${pallet.referencia}/${
                            pallet.sequencial
                          } ${pallet.descricao.split("MM ").pop()}`}
                          subtitle={new Date(
                            pallet.programacao
                          ).toLocaleDateString("pt-br", { timeZone: "UTC" })}
                          content={
                            <article>
                              <span className="subtitle">
                                {pallet.descricao}
                              </span>
                              <br />
                              Quantidade: {pallet.quantidade}
                            </article>
                          }
                        />
                      </article>
                    );
                  })
                ) : (
                  <article>Nenhum painel a reservar!</article>
                )}
              </section>
            </section>
          </section>
        </section>
        <Spinner active={spin} />
        <Atalhos />
      </section>
    </>
  );

  function handleFunilChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const funilVisivel = event.currentTarget.funil.value as
      | "produzido"
      | "acabamento"
      | "disponivel";

    const funis = {
      produzido: document.getElementById("produzido") as HTMLElement,
      acabamento: document.getElementById("acabamento") as HTMLElement,
      disponivel: document.getElementById("disponivel") as HTMLElement,
    };

    funis[funilVisivel].classList.add("visible");
    for (const funil of Object.keys(funis)) {
      const funilAtual = funil as "produzido" | "acabamento" | "disponivel";
      if (funilVisivel !== funil) funis[funilAtual].classList.remove("visible");
    }
  }

  function handleDiaChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    navigate(`/logistica/gestao_de_estoque/${event.currentTarget.dia.value}`);
  }

  async function avancarProducao(event: SyntheticEvent) {
    const id = event.currentTarget.id;
    setSpin(true);

    try {
      await pcp.patch(`/producao/estoque/${id}`);

      window.location.reload();
      alert("Pallet avançado para acabamento com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function avancarTodaProducao() {
    setSpin(true);

    try {
      if (estProduzido)
        for (const pallet of estProduzido) {
          await pcp.patch(`/producao/estoque/${pallet.id}`);
        }

      window.location.reload();
      alert("Pallets avançados para acabamento com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function avancarAcabamento(event: SyntheticEvent) {
    const id = event.currentTarget.id;
    setSpin(true);

    try {
      await pcp.patch(`/logistica/acabamento/${id}`);

      window.location.reload();
      alert("Pallet disponibilizado com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }

  async function avancarTodoAcabamento() {
    setSpin(true);

    try {
      await pcp.put(`/logistica/acabamento`, acabamento);

      window.location.reload();
      alert("Pallets disponibilizados com sucesso!");
    } catch (error) {
      setSpin(false);

      if (error instanceof AxiosError)
        if (error.response) return alert(JSON.stringify(error.response.data));

      return alert(`Ocorreu um erro ao processar atualização: ${error}`);
    }
  }
};
