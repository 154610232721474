import { RouteObject } from "react-router-dom";

import { Login } from "./Pages/Login/Login";
import { Aplicativos } from "./Pages/Aplicativos/Aplicativos";

export const authRouter: RouteObject[] = [
  {
    path: "/auth",
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "apps",
        element: <Aplicativos />,
      },
    ],
  },
];
